import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/common/PreloadResources/PreloadResources.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/common/SegmentIo/SegmentIoScript.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/common/SegmentIo/TrackPageEvents.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/contexts/MotionOptOutContext/MotionOptOutContext.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/contexts/TranslationContext/TranslationContext.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/contexts/UserAttributesContext/UserAttributesContext.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/styles.scss");
